<template>
  <div class="scheme">
    <BaseDialog
      :title="dioTitle"
      :isshow="visible"
      @handleShow="handleShow"
      width="1156px"
      height="auto"
    >
      <div class="schemeBox flex-start">
        <div class="scheme-left">
          <div class="scheme-type">
            <el-radio-group
              v-model="searchData.dict_advice_type"
              @change="dviceType"
            >
              <el-radio-button
                v-for="item in adviceTypeData"
                :key="item.id"
                :label="item.code"
                >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </div>
          <div
            class="scheme-tag parient-tag"
            v-show="searchData.dict_advice_type == '163110000'"
          >
            <span class="name">标签筛选：</span>
            <el-tag
              :class="item.active ? 'active' : ''"
              v-for="item in dictList[332900000]"
              :key="item.code"
              @click="tagBut(item)"
            >
              {{ item.name }}
            </el-tag>
          </div>
          <div
            class="scheme-tag parient-tag"
            v-show="searchData.dict_advice_type == '163120000'"
          >
            <span class="name">标签筛选：</span>
            <el-tag
              :class="item.active ? 'active' : ''"
              v-for="item in dictList[340200000]"
              :key="item.code"
              @click="tagBut(item)"
            >
              {{ item.name }}
            </el-tag>
          </div>
          <div class="scheme-search flex-between">
            <el-input
              @input="getDrugList"
              v-model="searchData.search_code"
              placeholder="搜索"
              clearable
            >
              <template #suffix><i class="fa fa-search"></i></template>
            </el-input>
            <el-select
              v-model="searchData.dict_type"
              @change="getDrugList"
              placeholder="请选择药品分类"
              clearable
            >
              <el-option
                v-for="item in dictList[160000000]"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </div>
          <div class="scheme-cont" v-loading="contLoading">
            <el-scrollbar ref="schemeRef" id="resultScroll">
              <div
                @click="drupBut(item)"
                class="item"
                :class="drugActive.id === item.id ? 'active' : ''"
                v-for="item in list"
                :key="item.id"
              >
                <div class="lable ellipsis">
                  {{ item.name }}/{{ item.medi_spec }}/{{
                    item.dict_factory_name
                  }}
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
        <div class="scheme-right">
          <el-scrollbar>
            <!-- <div class="scheme-check" v-if="ruleForm.is_preopen != '1'">
              <el-checkbox v-model="continuous">
                连续下达<i class="fa fa-question-circle"></i>
              </el-checkbox>
              <el-checkbox
                @change="customChe"
                v-if="ruleForm.is_preopen === '0'"
                v-model="custom"
              >
                自定义医嘱
              </el-checkbox>
            </div> -->
            <el-form
              ref="ruleForm1Ref"
              :model="ruleForm"
              :rules="Rule.LONGLERM"
              label-width="104px"
              label-position="right"
              class="demo-ruleForm col-333 lable-104"
            >
              <el-form-item
                label="开嘱医生："
                prop="create_doctor_id"
                v-if="ruleForm.is_preopen != '3'"
              >
                <el-select
                  v-model="ruleForm.create_doctor_id"
                  ref="selectRef"
                  placeholder="请选择开嘱医生"
                >
                  <el-option
                    v-for="item in userNameList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="开嘱时间："
                prop="start_time"
                v-if="ruleForm.is_preopen === '0'"
              >
                <el-date-picker
                  v-model="ruleForm.start_time"
                  type="date"
                  value-format="YYYY-MM-DD"
                  placeholder="请选择开嘱时间"
                ></el-date-picker>
              </el-form-item>
              <el-form-item
                label="结束时间："
                v-if="ruleForm.is_preopen === '0'"
              >
                <el-date-picker
                  v-model="ruleForm.end_time"
                  type="date"
                  value-format="YYYY-MM-DD"
                  placeholder="请选择结束时间"
                ></el-date-picker>
              </el-form-item>
              <el-form-item
                label="使用时间："
                prop="use_time"
                v-if="ruleForm.is_preopen === '1'"
              >
                <el-date-picker
                  v-model="ruleForm.use_time"
                  type="date"
                  value-format="YYYY-MM-DD"
                  placeholder="请选择使用时间"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="药品名称：" prop="drug">
                <el-input
                  v-model="ruleForm.drug"
                  placeholder="请输入药品名称"
                  :disabled="!custom"
                ></el-input>
              </el-form-item>
              <el-form-item label="规格：">
                <el-input
                  :disabled="!custom"
                  v-model="ruleForm.standard"
                  placeholder="请输入规格"
                >
                </el-input>
              </el-form-item>
              <el-form-item class="select" label="单次剂量：" prop="dosage">
                <el-input
                  v-model="ruleForm.dosage"
                  placeholder="请输入单次剂量"
                >
                  <template #append>
                    <el-select
                      v-model="ruleForm.dict_dosage_unit"
                      placeholder="单位"
                    >
                      <el-option
                        v-for="item in dictList[157000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item label="用药途径：" prop="dict_use_type">
                <el-select
                  v-model="ruleForm.dict_use_type"
                  placeholder="请选择用药途径"
                >
                  <el-option
                    v-for="item in dictList[158000000]"
                    :key="item.id"
                    :label="item.name"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="频次：" prop="dict_frequency">
                <el-select
                  v-model="ruleForm.dict_frequency"
                  placeholder="请选择频次"
                >
                  <el-option
                    v-for="item in dictList[159000000]"
                    :key="item.id"
                    :label="item.name"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="select" label="用药数量：" prop="num">
                <el-input v-model="ruleForm.num">
                  <template #append>
                    <el-select
                      v-model="ruleForm.num_unit"
                      :disabled="!custom"
                      placeholder="单位"
                    >
                      <el-option
                        v-for="item in dictList[176000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item label="收费组套：">
                <el-select
                  v-model="ruleForm.charge_group_id"
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in chargeGroupList"
                    :key="item.set_up_id"
                    :label="item.set_name"
                    :value="item.set_up_id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                class="switch"
                label="透中用药："
                v-if="ruleForm.is_preopen != '1'"
              >
                <el-switch :width="48" v-model="ruleForm.drug_type" />
                <i class="fa fa-question-circle"></i>
              </el-form-item>
              <el-form-item class="switch" label="个人库存：">
                <el-switch :width="48" v-model="ruleForm.self_provided" />
                <i class="fa fa-question-circle"></i>
              </el-form-item>
              <el-form-item
                class="switch"
                label="不记库存："
                v-if="ruleForm.is_preopen === '0'"
              >
                <el-switch :width="48" v-model="ruleForm.disable_stock" />
                <i class="fa fa-question-circle"></i>
              </el-form-item>
              <el-form-item
                label="循环周期："
                v-if="ruleForm.is_preopen === '0'"
              >
                <el-radio-group v-model="ruleForm.dict_cycle_loop.cycle">
                  <el-radio-button label="every_dialysis"
                    >每次透析</el-radio-button
                  >
                  <el-radio-button label="fixed_date">固定日期</el-radio-button>
                  <el-radio-button label="frequency">频次</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <div
                class="fixedDate"
                v-if="
                  ruleForm.is_preopen === '0' &&
                  ruleForm.dict_cycle_loop.cycle === 'fixed_date'
                "
              >
                <div class="date-top">
                  <el-radio-group v-model="ruleForm.dict_cycle_loop.week">
                    <el-radio label="every">每周</el-radio>
                    <el-radio label="single">单周</el-radio>
                    <el-radio label="double">双周</el-radio>
                  </el-radio-group>
                </div>
                <el-alert
                  :title="'本周为第' + week + '周'"
                  type="warning"
                  :closable="false"
                  show-icon
                >
                </el-alert>
                <div class="date-bottom">
                  <el-checkbox-group v-model="ruleForm.dict_cycle_loop.day">
                    <el-checkbox label="monday">周一</el-checkbox>
                    <el-checkbox label="tuesday">周二</el-checkbox>
                    <el-checkbox label="wednesday">周三</el-checkbox>
                    <el-checkbox label="thursday">周四</el-checkbox>
                    <el-checkbox label="friday">周五</el-checkbox>
                    <el-checkbox label="saturday">周六</el-checkbox>
                    <el-checkbox label="sunday">周日</el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <div
                class="frequency"
                v-if="
                  ruleForm.is_preopen === '0' &&
                  ruleForm.dict_cycle_loop.cycle === 'frequency'
                "
              >
                <el-form
                  ref="ruleForm2Ref"
                  :model="ruleForm.dict_cycle_loop"
                  :rules="Rule.LONGLERM"
                  label-width="260px"
                  label-position="right"
                  :inline="true"
                  class="demo-Form col-333"
                >
                  <el-form-item
                    class="switch"
                    label="每次用药间隔："
                    prop="time"
                  >
                    <el-input
                      v-model="ruleForm.dict_cycle_loop.time"
                    ></el-input>
                    次透析
                  </el-form-item>
                </el-form>
              </div>
              <el-form-item
                class="switch"
                label="院外用药："
                v-if="ruleForm.is_preopen != '1'"
              >
                <el-switch :width="48" v-model="ruleForm.is_out_drugs" />
                <i class="fa fa-question-circle"></i>
              </el-form-item>
              <el-form-item label="备注：" v-if="ruleForm.is_preopen != '1'">
                <el-input
                  v-model="ruleForm.remark"
                  maxlength="1000"
                  :rows="2"
                  placeholder="请输入备注"
                  show-word-limit
                  type="textarea"
                />
              </el-form-item>
            </el-form>
          </el-scrollbar>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="visible = false"
          >
            取消
          </el-button>
          <el-button
            class="color-determine"
            type="primary"
            :loading="saveLoading"
            @click="saveLongItem"
          >
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, nextTick } from 'vue'
import service from '@/utils/request'
import Rule from '@/utils/rule'
import { gitDictList, ruleTimeYear } from '@/utils/tool'
import { ElMessage, ElMessageBox } from 'element-plus'
import BaseDialog from '@/components/Dialog/index.vue'
export default {
  name: 'dialysisScheme',
  components: {
    BaseDialog,
  },
  props: ['patientId'],
  emits: ['refreshDataList'],
  setup(props, ctx) {
    const userInfo = JSON.parse(localStorage.getItem('ms_userData'))
    const state = reactive({
      dataForm: {},
      ruleForm: {
        dict_cycle_loop: {
          cycle: 'every_dialysis',
        },
      },
      searchData: {
        dict_advice_type: '',
        current: 1,
        size: 20,
        is_enable: 1,
      },
      Rule: Rule,
      list: [],
      dictList: {},
      adviceTypeData: [],
      visible: false,
      drugActive: {},
      actSetUpData: {},
      userNameList: [
        {
          name: localStorage.getItem('ms_username'),
          code: userInfo.uid,
          id: userInfo.uid,
        },
      ],
      week: '',
      assignUserId: '',
      continuous: false,
      custom: false,
      saveLoading: false,
      contLoading: false,
      ruleForm1Ref: null,
      ruleForm2Ref: null,
      schemeRef: null,
      dioTitle: '',
      chargeGroupList: [],
    })

    const initData = async (item, val, row) => {
      state.visible = true
      formInit()
      await getAdviceType()
      state.chargeGroupList = []
      state.searchData.dict_type = ''
      state.drugActive = {}
      console.log(item)
      if (item && item.id) {
        state.ruleForm = item
        if (!item.dict_cycle_loop) {
          state.ruleForm.dict_cycle_loop = {
            cycle: 'every_dialysis',
            week: 'every',
          }
        }
        if (item.drug_id) {
          getDrugSet(item.drug_id, true)
        }
        if (item.create_doctor_id != userInfo.uid && val != '3') {
          state.assignUserId = JSON.parse(JSON.stringify(item)).create_doctor_id
          state.ruleForm.create_doctor_id = item.doctor.name
          console.log(state.assignUserId)
        }
        if (item.dict_drug_type) {
          state.searchData.dict_type = item.dict_drug_type
        }
        state.ruleForm.dict_dosage_unit =
          state.ruleForm.dict_dosage_unit.toString()
        state.ruleForm.num_unit = state.ruleForm.num_unit.toString()
        if (state.ruleForm.drug_type) {
          state.ruleForm.drug_type = true
        } else {
          state.ruleForm.drug_type = false
        }
        if (state.ruleForm.self_provided) {
          state.ruleForm.self_provided = true
        } else {
          state.ruleForm.self_provided = false
        }
        if (state.ruleForm.disable_stock) {
          state.ruleForm.disable_stock = true
        } else {
          state.ruleForm.disable_stock = false
        }
        if (state.ruleForm.is_out_drugs) {
          state.ruleForm.is_out_drugs = true
        } else {
          state.ruleForm.is_out_drugs = false
        }
      }
      getDrugList()
      // is_preopen 0:长期 1:预开 3.组套 4.临时
      state.ruleForm.is_preopen = val
      if (val === '3') {
        state.actSetUpData = row
      }
      if (state.ruleForm.is_preopen === '1') {
        if (state.ruleForm.id) {
          state.dioTitle = '修改预开医嘱'
        } else {
          state.dioTitle = '新增预开医嘱'
        }
      } else {
        if (state.ruleForm.id) {
          state.dioTitle = '修改医嘱'
        } else {
          state.dioTitle = '新增医嘱'
        }
      }
    }

    onMounted(() => {
      getDict()
      getWeek()
    })
    const handleScroll = async () => {
      let scrollbarEl = state.schemeRef.wrap$
      const scrollTop = scrollbarEl.scrollTop
      const scrollHeight = scrollbarEl.scrollHeight
      const offsetHeight = scrollbarEl.offsetHeight
      if (offsetHeight + scrollTop == scrollHeight) {
        state.searchData.current++
        curDrugList()
      }
    }
    const getAdviceType = async () => {
      let res = await service.post('/api/advice/type')
      if (res.code === 0) {
        state.adviceTypeData = res.data
        if (res.data) {
          let keyArr = Object.keys(res.data)
          state.searchData.dict_advice_type = res.data[keyArr[0]].code
        }
      }
    }
    // 标签筛选
    const tagBut = (row) => {
      row.active = !row.active
      let tabList = []
      let item = []
      if (state.searchData.dict_advice_type == '163110000') {
        item = state.dictList[332900000]
      } else {
        item = state.dictList[340200000]
      }
      for (let i = 0; i < item.length; i++) {
        if (item[i].active) {
          tabList.push(item[i].code)
        }
      }
      state.searchData.tag = tabList
      getDrugList()
    }
    const getDrugList = () => {
      state.searchData.current = 1
      curDrugList()
    }
    const curDrugList = async () => {
      if (state.searchData.current == 1) {
        state.contLoading = true
      }
      let res = await service.post('/api/drug/list', state.searchData)
      state.contLoading = false
      if (res.code === 0) {
        if (state.searchData.current == 1) {
          state.list = res.data.records
        } else {
          state.list = state.list.concat(res.data.records)
        }
      }
    }
    const getDict = async () => {
      state.dictList = await gitDictList([
        '160000000',
        '157000000',
        '158000000',
        '159000000',
        '176000000',
        '332900000',
        '340200000',
      ])
    }
    const getWeek = async () => {
      let res = await service.post('/api/week/get_week')
      if (res.code === 0) {
        state.week = res.data.week
      }
    }
    const formInit = () => {
      state.ruleForm = {}
      state.ruleForm = {
        create_doctor_id: userInfo.uid,
        start_time: ruleTimeYear(new Date()),
        use_time: ruleTimeYear(new Date()),
        num: 1,
        drug_type: true,
        self_provided: false,
        disable_stock: false,
        is_out_drugs: false,
        dict_cycle_loop: {
          cycle: 'every_dialysis',
          week: 'every',
        },
      }
      nextTick(() => {
        document
          .getElementById('resultScroll')
          .addEventListener('scroll', handleScroll, true)
      })
    }

    const customChe = (val) => {
      if (val) {
        ElMessageBox.confirm(
          '自定义医嘱在执行后不再自动添加耗材记录，确定要自定义编辑吗？',
          '操作提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(async () => {})
          .catch(() => {
            state.custom = false
          })
      }
    }

    const drupBut = (item) => {
      state.drugActive = item
      state.ruleForm.drug = item.name
      state.ruleForm.drug_id = item.id
      state.ruleForm.standard = item.medi_spec
      state.ruleForm.dosage = item.dosage
      state.ruleForm.dict_dosage_unit = item.dict_base_unit
      state.ruleForm.dict_use_type = item.dict_use_way
      state.ruleForm.dict_frequency = item.dict_frequency
      state.ruleForm.dict_drug_type = item.dict_type
      state.ruleForm.num_unit = item.dict_first_unit
      getDrugSet(item.id)
    }
    const getDrugSet = async (val, flag) => {
      let res = await service.post('/api/SetUp/getDrugSet', {
        drug_id: val,
        current: 1,
        size: 10000,
      })
      state.contLoading = false
      if (res.code === 0) {
        state.chargeGroupList = res.data.records
        if (state.chargeGroupList.length > 0 && !flag) {
          state.ruleForm.charge_group_id = state.chargeGroupList[0].set_up_id
        }
      }
    }
    const dviceType = () => {
      state.drugActive = {}
      state.ruleForm.drug = ''
      state.ruleForm.drug_id = ''
      state.ruleForm.standard = ''
      state.ruleForm.dosage = ''
      state.ruleForm.dict_dosage_unit = ''
      state.ruleForm.dict_use_type = ''
      state.ruleForm.dict_frequency = ''
      state.ruleForm.dict_drug_type = ''
      state.ruleForm.num_unit = ''
      state.ruleForm.charge_group_id = ''
      state.chargeGroupList = []
      state.searchData.tag = []
      state.dictList[332900000].map((item) => {
        item.active = false
      })
      state.dictList[340200000].map((item2) => {
        item2.active = false
      })
      getDrugList()
    }

    const handleShow = (val) => {
      state.visible = val
      if (val === false) {
        document
          .getElementById('resultScroll')
          .removeEventListener('scroll', handleScroll, true)
      }
    }

    const ruleForm = (formEl) => {
      let flag = false
      if (!state[formEl]) return true
      state[formEl].validate((valid) => {
        if (valid) {
          flag = true
        } else {
          flag = false
        }
      })
      return flag
    }

    const saveLongItem = async () => {
      const rule1 = ruleForm('ruleForm1Ref')
      const rule2 = ruleForm('ruleForm2Ref')
      if (rule1 && rule2) {
        if (!state.ruleForm.dict_dosage_unit) {
          ElMessage.error('请选择单次剂量单位')
          return
        }
        if (
          state.ruleForm.is_preopen != '3' &&
          state.ruleForm.create_doctor_id != userInfo.uid
        ) {
          state.ruleForm.create_doctor_id = state.assignUserId
        }
        if (state.custom) {
          state.ruleForm.is_custom = 1
        } else {
          state.ruleForm.is_custom = 0
        }
        state.ruleForm.patient_id = props.patientId
        state.ruleForm.dict_advice_type = state.searchData.dict_advice_type
        state.saveLoading = true
        let res = ''
        if (state.ruleForm.is_preopen != '3') {
          if (state.ruleForm.id) {
            res = await service.post('/api/advice/edit', state.ruleForm)
          } else {
            res = await service.post('/api/advice/create', state.ruleForm)
          }
        } else {
          if (state.ruleForm.id) {
            res = await service.post(
              '/api/advice_group_item/edit',
              state.ruleForm
            )
          } else {
            state.ruleForm.group_id = state.actSetUpData.id
            res = await service.post(
              '/api/advice_group_item/create',
              state.ruleForm
            )
          }
        }
        state.saveLoading = false
        if (res.code === 0) {
          ElMessage.success(res.msg)
          if (!state.continuous) {
            state.visible = false
          }
          ctx.emit('refreshDataList')
        }
      }
    }

    return {
      ...toRefs(state),
      initData,
      handleShow,
      getDrugList,
      drupBut,
      saveLongItem,
      customChe,
      tagBut,
      dviceType,
    }
  },
}
</script>

<style scoped lang="scss">
.el-input {
  width: 224px;
  // margin-right: 62px;
}
.schemeBox {
  margin-top: 24px;
}
.scheme-left {
  width: 632px;
  margin-right: 32px;
}
.fixedDate {
  margin-left: 20px;
}
.demo-Form {
  margin-top: 0;
  .el-form-item {
    width: 100%;
  }
  &:deep(.el-form-item__content) {
    // width: calc(100% - 260px);
    font-size: 14px;
    font-family: 'Source Han Sans CN-Regular';
    color: #444444;
  }
  &:deep(.el-form-item .el-input) {
    width: 120px;
    margin-right: 2px;
  }
}

.scheme-tag {
  background: rgba(49, 102, 174, 0.1);
  border-radius: 8px;
  padding: 4px 20px;
  padding-bottom: 0;
  margin: 16px 0;
  .name {
    font-size: 14px;
    color: #3166ae;
  }
  .el-tag {
    margin-bottom: 4px;
  }
}

.scheme-cont {
  padding: 0 10px;
  margin-top: 20px;
  border-right: 1px solid #e0e0e0;
  height: 400px;
  padding-left: 0;
  .item {
    height: 32px;
    line-height: 32px;
    width: 100%;
    font-size: 14px;
    font-family: 'Source Han Sans CN-Medium';
    color: #444444;
    padding-right: 14px;
    margin-bottom: 12px;
    .lable {
      background-color: #ffffff;
      border-radius: 4px;
      padding-left: 20px;
      cursor: pointer;
    }
    &.active {
      border-right: 4px solid #3166ae;
      .lable {
        background-color: rgba(49, 102, 174, 0.3);
        color: #3166ae;
      }
    }
    &:hover {
      .lable {
        background-color: rgba(49, 102, 174, 0.3);
        color: #3166ae;
      }
    }
  }
}

.scheme-right {
  width: 444px;
  height: 552px;
  .fa {
    color: #3166ae;
    margin-left: 12px;
  }
  .fixedDate {
    width: 348px;
    margin-left: 22px;
    .el-radio {
      margin-right: 92px;
      &:last-child {
        margin-right: 0;
      }
    }
    .el-alert {
      margin: 10px 0 20px;
      color: #ff3e3e;
    }
    .el-checkbox {
      margin-right: 92px;
      margin-bottom: 8px;
      &:nth-child(3n + 3) {
        margin-right: 0px;
      }
    }
  }
  .el-form-item {
    margin-bottom: 18px;
    margin-right: 0;
    .fa {
      font-size: 22px;
    }
    &.icon {
      &:deep(.el-input) {
        width: 232px;
        margin-right: 0;
      }
    }
  }
  &:deep(.el-radio-group.height-40 .el-radio-button__inner) {
    padding: 12px 26px;
  }
  &:deep(.el-input),
  &:deep(.el-form .el-form-item__content .el-date-editor.el-input) {
    width: 264px;
  }
  &:deep(.el-input-group__append .el-input) {
    width: 90px;
  }
  &:deep(.el-textarea) {
    width: 338px;
  }
}

.dialog-footer {
  text-align: right;
  margin-top: 20px;
  .color-cancel {
    background: #ffffff;
    color: #3166ae;
    margin-right: 10px;
  }
}
</style>
